<template>
  <div class="login-container">
    <div class="login-content">
      <el-form
        ref="loginFormRef"
        class="login-form"
        :model="loginForm"
        :rules="loginFormRules"
      >
        <div class="title-container">
          <!-- <img src="../../assets/img/logo.png" class="DHCLogo" /> -->
          <h3 class="title">朝日唯品循环土壤后台系统</h3>
        </div>
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            clearable
            placeholder="用户名"
            size="large"
          >
            <template #prepend>
              <I name="UserFilled" size="14" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="密码"
            size="large"
            :type="passwordType"
            @keyup.enter="submitForm"
          >
            <template #prepend>
              <I
                v-if="passwordLock"
                name="Lock"
                size="14"
                @click="switchPass"
              />
              <I v-else name="Unlock" size="14" @click="switchPass" />
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" :loading="btnLoading" @click="submitForm">
            登录
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { setToken } from '@/utils/auth'
import { user_login, getUser } from '@/api/user'
import store from '@/store'
const router = useRouter()

const btnLoading = ref(false)
const passwordLock = ref(true)
const passwordType = ref('password')

const loginFormRef = ref()
const loginForm = reactive({
  username: '',
  password: ''
})

const loginFormRules = reactive({
  username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
  password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
})

const switchPass = () => {
  if (passwordLock.value) {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
  passwordLock.value = !passwordLock.value
}
const submitForm = () => {
  loginFormRef.value?.validate(valid => {
    if (valid) {
      btnLoading.value = true
      user_login(loginForm)
        .then(async ({ data: res }) => {
          btnLoading.value = false
          if (res.code == 200) {
            setToken(res.data)
            getUserInfo()
            // setTimeout(() => {
            //   store.dispatch('app/menuLists')
            // }, 500)
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(err => {
          btnLoading.value = false
        })
    } else {
      ElMessage.error('请输入用户名和密码')
    }
  })
}

const getUserInfo = () => {
  getUser()
    .then(async ({ data: res }) => {
      if (res.code == 200) {
        localStorage.setItem('role', res.data.nickname)
        localStorage.setItem('username', res.data.username)
        router.push('/')
      } else {
        ElMessage.error(res.msg)
      }
    })
    .catch(err => {
      btnLoading.value = false
    })
}
</script>

<style lang="scss">
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
.DHCLogo {
  width: 236px;
  height: 78px;
  display: block;
  margin: 0 auto 30px;
}
@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 100%;
    .el-input__wrapper {
      width: 85%;
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 100%;
      caret-color: $cursor;
      box-shadow: none;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
    .el-input__inner {
      &:-webkit-autofill {
        -webkit-text-fill-color: #ededed !important;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
        background-color: transparent;
        background-image: none;
        transition: background-color 900000000s ease-in-out 0s;
      }
    }
    .el-input-group__prepend {
      color: #fff;
      background: transparent;
      box-shadow: none;
      cursor: pointer;
    }
  }
  .el-form-item__error {
    margin-top: 5px;
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    margin-bottom: 25px;
  }
  .login-btn {
    .el-button {
      width: 100%;
      height: 47px;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    margin: -100px auto 0;
    overflow: hidden;
  }
  .title-container {
    position: relative;
    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
